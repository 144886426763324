import React, {Component} from "react"
import style from "../pages/contact.module.scss"
import ImageForm from "../images/contacto/contacto.jpg"
export default class Form extends Component {
    constructor(props) {
        super();
        this.state = {
            active: false,
            align: props.align ? props.align : 'top',
            image: props.image ? props.image : ImageForm,
            options: props.options ? props.options : ['Share At Sea: Fractional Navigation', 'Princess Yachts: New Yacht', 'Textron: New Aircraft',
            'PreOwned: Aircraft Brokerage', 'MexJet: Fractional Aircraft/Jetcards (hrs)', 'FBO: Aircraft Management/Hangar',
            'Helicopters: New/Brokerage', 'Ale Service Center: Workshop' ]
        }
        this.closeAlert = this.closeAlert.bind(this);
        this.openAlert = this.openAlert.bind(this)
    }
    openAlert() {
        this.setState({active: true})
    }
    closeAlert() {
        this.setState({active: false})
    }

    render() {
        return (
            <>
                <div className={`${style.mainButton} ${style[this.state.align]}`}>
                    <button onClick={this.openAlert}>Contacte un representante</button>
                </div>
                <div className={this.state.active ? `${style.contactForm} ${style.active}` : style.contactForm}>
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                    <img src={this.state.image} alt={"contacto"}/>
                        <button type='button' className={style.close} onClick={this.closeAlert}>X</button>

                        <input type="hidden" name="oid" value="00DA0000000AZhF"/>
                        <input type="hidden" name="retURL" value="http://aerolineasejecutivas.com"/>
                        <label htmlFor="first_name">
                            <p>Nombre</p>
                            <input id="first_name" required maxLength="40" name="first_name" size="20" type="text"/><br/>
                        </label>

                        <label htmlFor="last_name">
                            <p>Apellidos</p>
                            <input id="last_name" required maxLength="80" name="last_name" size="20" type="text"/><br/>
                        </label>

                        <label htmlFor="email">
                            <p>Email</p>
                            <input id="email" required maxLength="80" name="email" size="20" type="text"/><br/>
                        </label>

                        <label htmlFor="company">
                            <p>Compañia</p>
                            <input id="company" required maxLength="40" name="company" size="20" type="text"/><br/>
                        </label>

                        <label htmlFor="city">
                            <p>Ciudad</p>
                            <input id="city" required maxLength="40" name="city" size="20" type="text"/><br/>
                        </label>

                        <label htmlFor="country_code">
                            <p>Pais</p>
                            <select id="country_code" name="country_code">
                                <option value="">--None--</option>
                                <option value="Afghanistan">Afghanistan</option>
                <option value="Åland Islands">Åland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'ivoire">Cote D'ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-bissau">Guinea-bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-leste">Timor-leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                        </label>

                        <label htmlFor="city">
                            <p>Estado</p>
                            <input id="state" required maxLength="40" name="state" size="20" type="text"/><br/>
                        </label>

                        <label htmlFor="state_code">
                            <p>Estado</p>
                            <select id="state_code" name="state_code">
                                <option value="">--None--</option>
                                <option value="AC">AC</option>
                                <option value="AG">AG</option>
                                <option value="AG">AG</option>
                                <option value="AL">AL</option>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AB">AB</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="AN">AN</option>
                                <option value="AN">AN</option>
                                <option value="AP">AP</option>
                                <option value="34">34</option>
                                <option value="AO">AO</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="AR">AR</option>
                                <option value="AR">AR</option>
                                <option value="AP">AP</option>
                                <option value="AS">AS</option>
                                <option value="AT">AT</option>
                                <option value="ACT">ACT</option>
                                <option value="AV">AV</option>
                                <option value="BA">BA</option>
                                <option value="BC">BC</option>
                                <option value="BS">BS</option>
                                <option value="BA">BA</option>
                                <option value="BT">BT</option>
                                <option value="11">11</option>
                                <option value="BL">BL</option>
                                <option value="BN">BN</option>
                                <option value="BG">BG</option>
                                <option value="BI">BI</option>
                                <option value="BR">BR</option>
                                <option value="BO">BO</option>
                                <option value="BZ">BZ</option>
                                <option value="BS">BS</option>
                                <option value="BR">BR</option>
                                <option value="BC">BC</option>
                                <option value="CA">CA</option>
                                <option value="CA">CA</option>
                                <option value="CL">CL</option>
                                <option value="CM">CM</option>
                                <option value="CB">CB</option>
                                <option value="CI">CI</option>
                                <option value="CW">CW</option>
                                <option value="CE">CE</option>
                                <option value="CT">CT</option>
                                <option value="CZ">CZ</option>
                                <option value="CN">CN</option>
                                <option value="CE">CE</option>
                                <option value="CH">CH</option>
                                <option value="CT">CT</option>
                                <option value="CS">CS</option>
                                <option value="CH">CH</option>
                                <option value="CH">CH</option>
                                <option value="50">50</option>
                                <option value="CE">CE</option>
                                <option value="CO">CO</option>
                                <option value="CL">CL</option>
                                <option value="CO">CO</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="CO">CO</option>
                                <option value="CS">CS</option>
                                <option value="CR">CR</option>
                                <option value="KR">KR</option>
                                <option value="CN">CN</option>
                                <option value="DN">DN</option>
                                <option value="DD">DD</option>
                                <option value="DE">DE</option>
                                <option value="DL">DL</option>
                                <option value="DC">DC</option>
                                <option value="DF">DF</option>
                                <option value="DL">DL</option>
                                <option value="D">D</option>
                                <option value="DG">DG</option>
                                <option value="EN">EN</option>
                                <option value="ES">ES</option>
                                <option value="DF">DF</option>
                                <option value="FM">FM</option>
                                <option value="FE">FE</option>
                                <option value="FI">FI</option>
                                <option value="FL">FL</option>
                                <option value="FG">FG</option>
                                <option value="FC">FC</option>
                                <option value="FR">FR</option>
                                <option value="35">35</option>
                                <option value="G">G</option>
                                <option value="62">62</option>
                                <option value="GE">GE</option>
                                <option value="GA">GA</option>
                                <option value="GA">GA</option>
                                <option value="GO">GO</option>
                                <option value="GO">GO</option>
                                <option value="GR">GR</option>
                                <option value="GT">GT</option>
                                <option value="44">44</option>
                                <option value="45">45</option>
                                <option value="GR">GR</option>
                                <option value="52">52</option>
                                <option value="GJ">GJ</option>
                                <option value="46">46</option>
                                <option value="HR">HR</option>
                                <option value="HI">HI</option>
                                <option value="13">13</option>
                                <option value="23">23</option>
                                <option value="41">41</option>
                                <option value="HG">HG</option>
                                <option value="HP">HP</option>
                                <option value="91">91</option>
                                <option value="42">42</option>
                                <option value="43">43</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IM">IM</option>
                                <option value="IN">IN</option>
                                <option value="IA">IA</option>
                                <option value="IS">IS</option>
                                <option value="JA">JA</option>
                                <option value="JK">JK</option>
                                <option value="JH">JH</option>
                                <option value="32">32</option>
                                <option value="36">36</option>
                                <option value="22">22</option>
                                <option value="KS">KS</option>
                                <option value="KA">KA</option>
                                <option value="KY">KY</option>
                                <option value="KL">KL</option>
                                <option value="KY">KY</option>
                                <option value="KE">KE</option>
                                <option value="KK">KK</option>
                                <option value="AQ">AQ</option>
                                <option value="LD">LD</option>
                                <option value="LS">LS</option>
                                <option value="SP">SP</option>
                                <option value="LT">LT</option>
                                <option value="LE">LE</option>
                                <option value="LC">LC</option>
                                <option value="LM">LM</option>
                                <option value="21">21</option>
                                <option value="LK">LK</option>
                                <option value="LI">LI</option>
                                <option value="LO">LO</option>
                                <option value="LD">LD</option>
                                <option value="LA">LA</option>
                                <option value="LH">LH</option>
                                <option value="LU">LU</option>
                                <option value="92">92</option>
                                <option value="MC">MC</option>
                                <option value="MP">MP</option>
                                <option value="MH">MH</option>
                                <option value="ME">ME</option>
                                <option value="MN">MN</option>
                                <option value="MB">MB</option>
                                <option value="MN">MN</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="MS">MS</option>
                                <option value="MA">MA</option>
                                <option value="MT">MT</option>
                                <option value="MT">MT</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MH">MH</option>
                                <option value="VS">VS</option>
                                <option value="ML">ML</option>
                                <option value="ME">ME</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MI">MI</option>
                                <option value="MI">MI</option>
                                <option value="MG">MG</option>
                                <option value="MN">MN</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MZ">MZ</option>
                                <option value="MO">MO</option>
                                <option value="MN">MN</option>
                                <option value="MT">MT</option>
                                <option value="MB">MB</option>
                                <option value="MO">MO</option>
                                <option value="NL">NL</option>
                                <option value="NA">NA</option>
                                <option value="NA">NA</option>
                                <option value="NE">NE</option>
                                <option value="15">15</option>
                                <option value="NV">NV</option>
                                <option value="NB">NB</option>
                                <option value="NL">NL</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NSW">NSW</option>
                                <option value="NY">NY</option>
                                <option value="64">64</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NT">NT</option>
                                <option value="NT">NT</option>
                                <option value="NO">NO</option>
                                <option value="NS">NS</option>
                                <option value="NL">NL</option>
                                <option value="NU">NU</option>
                                <option value="NU">NU</option>
                                <option value="OA">OA</option>
                                <option value="OR">OR</option>
                                <option value="OY">OY</option>
                                <option value="OG">OG</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OT">OT</option>
                                <option value="ON">ON</option>
                                <option value="OR">OR</option>
                                <option value="OR">OR</option>
                                <option value="PD">PD</option>
                                <option value="PA">PA</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PR">PR</option>
                                <option value="PV">PV</option>
                                <option value="PA">PA</option>
                                <option value="PE">PE</option>
                                <option value="PG">PG</option>
                                <option value="PU">PU</option>
                                <option value="PE">PE</option>
                                <option value="PC">PC</option>
                                <option value="PI">PI</option>
                                <option value="PI">PI</option>
                                <option value="PT">PT</option>
                                <option value="PN">PN</option>
                                <option value="PZ">PZ</option>
                                <option value="PO">PO</option>
                                <option value="PE">PE</option>
                                <option value="PY">PY</option>
                                <option value="PB">PB</option>
                                <option value="PB">PB</option>
                                <option value="63">63</option>
                                <option value="QC">QC</option>
                                <option value="QLD">QLD</option>
                                <option value="QE">QE</option>
                                <option value="QR">QR</option>
                                <option value="RG">RG</option>
                                <option value="RJ">RJ</option>
                                <option value="RA">RA</option>
                                <option value="RC">RC</option>
                                <option value="RE">RE</option>
                                <option value="RI">RI</option>
                                <option value="RI">RI</option>
                                <option value="RN">RN</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RM">RM</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="RN">RN</option>
                                <option value="RO">RO</option>
                                <option value="SA">SA</option>
                                <option value="SL">SL</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SK">SK</option>
                                <option value="SS">SS</option>
                                <option value="SV">SV</option>
                                <option value="SE">SE</option>
                                <option value="61">61</option>
                                <option value="37">37</option>
                                <option value="31">31</option>
                                <option value="14">14</option>
                                <option value="51">51</option>
                                <option value="SI">SI</option>
                                <option value="SK">SK</option>
                                <option value="SI">SI</option>
                                <option value="SO">SO</option>
                                <option value="SO">SO</option>
                                <option value="SO">SO</option>
                                <option value="SA">SA</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="SR">SR</option>
                                <option value="TB">TB</option>
                                <option value="71">71</option>
                                <option value="TM">TM</option>
                                <option value="TN">TN</option>
                                <option value="TA">TA</option>
                                <option value="TAS">TAS</option>
                                <option value="TN">TN</option>
                                <option value="TE">TE</option>
                                <option value="TR">TR</option>
                                <option value="TX">TX</option>
                                <option value="12">12</option>
                                <option value="TA">TA</option>
                                <option value="TL">TL</option>
                                <option value="TO">TO</option>
                                <option value="TP">TP</option>
                                <option value="TN">TN</option>
                                <option value="TV">TV</option>
                                <option value="TS">TS</option>
                                <option value="TR">TR</option>
                                <option value="TO">TO</option>
                                <option value="UD">UD</option>
                                <option value="UT">UT</option>
                                <option value="UT">UT</option>
                                <option value="UP">UP</option>
                                <option value="VA">VA</option>
                                <option value="VE">VE</option>
                                <option value="VE">VE</option>
                                <option value="VB">VB</option>
                                <option value="VC">VC</option>
                                <option value="VT">VT</option>
                                <option value="VR">VR</option>
                                <option value="VV">VV</option>
                                <option value="VI">VI</option>
                                <option value="VIC">VIC</option>
                                <option value="VA">VA</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WD">WD</option>
                                <option value="WB">WB</option>
                                <option value="WA">WA</option>
                                <option value="WH">WH</option>
                                <option value="WV">WV</option>
                                <option value="WX">WX</option>
                                <option value="WW">WW</option>
                                <option value="WI">WI</option>
                                <option value="WY">WY</option>
                                <option value="65">65</option>
                                <option value="54">54</option>
                                <option value="YU">YU</option>
                                <option value="YT">YT</option>
                                <option value="53">53</option>
                                <option value="ZA">ZA</option>
                                <option value="33">33</option>
                            </select>
                        </label>

                        <label htmlFor="">
                            <p>Producto de Interés</p>
                            <select id="00N0G00000Dq27r" name="00N0G00000Dq27r" title="Product of Interest">
                                {this.state.options.map((item)=>{
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </label>

                        <button type="submit">Enviar</button>

                    </form>
                </div>
            </>
        );
    }
}
